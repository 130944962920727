import React from 'react'
import { useState } from 'react';
import FootLogo from '../assets/images/foot-logo.svg'
import { NavLink } from 'react-router-dom'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { getOperatingTime } from '../service/UserApi';
import { GlobalContext } from '../globalContext';
import moment from 'moment';

const Footer = () => {
    const LoginPage = ['/admin/login',];
    const { dispatch,state } = React.useContext(GlobalContext);
    const {businessInfo} = state
    const HideHeader = LoginPage.includes(location.pathname);
    // const [businessInfo, setbusinessInfo] = useState([])



    const getData = async () => {
        const OperatingData = await getOperatingTime();
        // setbusinessInfo(OperatingData);
        dispatch({
            type: "UPDATE_BUSS_INFO",
            payload: OperatingData,
          })
    };


    React.useEffect(() => {
        getData()
    }, []);


    const formatTime = (timeString) => {
        if (!timeString) {
            // console.error('Invalid time string:', timeString);
            return '';
        }
        return moment(timeString,'HH:mm:ss').format('hh:mm a');
    };

    return (
        <>
            {HideHeader ? '' : <footer className=" bg-[#1C1C1C] py-20 pb-[30px]">
                <div className="container mx-auto">
                    <div className="flex md:flex-row flex-col gap-8 justify-between">
                        <div className="flex flex-col gap-[60px]">
                            <img className='max-w-[138px] w-full' src={FootLogo} alt='' />
                            <div className="flex flex-col gap-6 foot-list">
                                <NavLink to={`tel:${businessInfo?.phone_number}`} className="flex items-center gap-2">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z" fill="white" />
                                    </svg>
                                    {businessInfo?.phone_number}
                                </NavLink>
                                <NavLink to={`mailto:${businessInfo?.email}`} className="flex items-center gap-2">
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196667 15.0217 0.000666667 14.5507 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196667 1.45067 0.000666667 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.805 0.98 20.0007 1.45067 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.0217 15.805 18.5507 16.0007 18 16H2ZM10 9L18 4V2L10 7L2 2V4L10 9Z" fill="white" />
                                    </svg>

                                    {businessInfo?.email}
                                </NavLink>
                                <p className="flex items-center gap-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="white" />
                                    </svg>

                                    {businessInfo?.location}
                                </p>
                            </div>
                        </div>


                        <div className="operating-hours md:max-w-[400px] max-w-[100%] wi-full">
                            <h5 className='text-[#F38030] text-[16px] font-[500] mb-4'>Operating Hours</h5>
                            <div className="grid grid-cols-2">
                            <p>Sunday</p>
                <p>{formatTime(businessInfo?.sunday_start_time)} - {formatTime(businessInfo?.sunday_end_time)}</p>

                <p>Monday</p>
                <p>{formatTime(businessInfo?.monday_start_time)} - {formatTime(businessInfo?.monday_end_time)}</p>

                <p>Tuesday</p>
                <p>{formatTime(businessInfo?.tuesday_start_time)} - {formatTime(businessInfo?.tuesday_end_time)}</p>

                <p>Wednesday</p>
                <p>{formatTime(businessInfo?.wednesday_start_time)} - {formatTime(businessInfo?.wednesday_end_time)}</p>

                <p>Thursday</p>
                <p>{formatTime(businessInfo?.thursday_start_time)} - {formatTime(businessInfo?.thursday_end_time)}</p>

                <p>Friday</p>
                <p>{formatTime(businessInfo?.friday_start_time)} - {formatTime(businessInfo?.friday_end_time)}</p>

                <p>Saturday</p>
            

            
                <p>{formatTime(businessInfo?.saturday_start_time)} - {formatTime(businessInfo?.saturday_end_time)}</p>
                            </div>
                        </div>

                    </div>

                    <div className="foot-bottom flex justify-between items-center gap-4 flex-wrap   ">
                        <div className="flex items-center gap-7">
                            <NavLink to='/contact'>Contact us</NavLink>
                            {/* <NavLink to='#'>Sign in</NavLink>
                            <NavLink to='#'>Sign up</NavLink> */}
                            <NavLink style={{ color: 'white', fontSize: '12px' }} to={"https://parkndepart.io/"}>
                                © Copyright {(new Date().getFullYear())} - parkndepart.io
                            </NavLink>
                        </div>
                        <div className="flex items-center gap-7">
                            <NavLink to='/privacy_&_policy  '>Privacy Policy</NavLink>
                            <NavLink to='/refund'>Refund Policy</NavLink>
                            <NavLink to='/terms_&_condition'>Terms & Conditions</NavLink>
                        </div>
                    </div>
                </div>

            </footer>}

        </>
    )
}

export default Footer
