import React from "react";
import Logo from "../assets/images/logo.png";
import { GlobalContext } from "../globalContext";

const ReservationDetailsCard = () => {
  const { state } = React.useContext(GlobalContext);
  const {businessInfo} = state
  const { reservation } = state;
  const {days,checkin,checkout,reservationResponse} = reservation
  let { userName } = state;
  const {data} = reservationResponse
    if(data==null) return <div></div>
  return (
    <div className=" md:grid-cols-2 grid-cols-1 md:gap-6 gap-10 mt-7 grid ">
      <div className="block bg-[white] shadow-lg p-3 rounded-[4px]">
        <span className="bg-[#F9EEE6] uppercase py-2 px-4 text-[12px] text-[#F38D46] rounded-[10px] mt-[-30px] block w-fit">
          Parking lot
        </span>
        <img className="max-w-[100px] w-full ml-auto" src={Logo} alt="" />
        <h3 className="text-[#2B2B2B] text-[16px]">Park N Depart</h3>
        <p className="text-[#707070] text-[12px]">
          
          {businessInfo?.location}
        </p>
      </div>
      <div className="block bg-[white] shadow-lg p-3 rounded-[4px]">
        <span className="bg-[#F9EEE6] uppercase py-2 px-4 text-[12px] text-[#F38D46] rounded-[10px] mt-[-30px] block w-fit">
          Dates & Options
        </span>
        <h5 className="text-[#F38030] text-[12px] text-right">
          Reservation for {days} days
        </h5>
        <p className="text-[#707070] text-[10px]">Person Parking:</p>
        <div className="flex items-center justify-between">
        <h4 className="text-[#2B2B2B] text-[12px] font-[400]">{data?.user_name} </h4>
        
        <h4 className="text-[#2B2B2B] text-[12px] font-[400]">{data?.email} </h4>
        </div>
        <div className="grid grid-cols-2 mt-3">
          <div className="flex flex-col ">
            <p className="text-[#707070] text-[10px]">Check in</p>
            <p className="text-[#2B2B2B] text-[12px]">{checkin}</p>
          </div>
          <div className="flex flex-col ">
            <p className="text-[#707070] text-[10px]">Check out</p>
            <p className="text-[#2B2B2B] text-[12px]">{checkout}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetailsCard;
