import React from 'react'
import PaymentButton from './PaymentButton'
import GooglePay from '../assets/images/google-pay.svg'
import ApplePay from '../assets/images/apple-pay.svg'
import CashPay from '../assets/images/cash-pay.svg'
import Paypal from '../assets/images/paypal.svg'
import PayWithLink from './PayWithLink'
import PayWithCardButton from './PayWithCardButton'
import CardPaymentForm from './CardPaymentForm'
import { GlobalContext } from "../globalContext";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';






const SelectPayment = () => {

    const { state } = React.useContext(GlobalContext);


    let { PaymentType } = state





    return (
        <>
     
        <div className='block'>
            <div className="text-center">
                <h5 className='clash text-[#2B2B2B] text-[24px] mb-2'>Select Payment Method</h5>
                <p className='text-[#707070] text-[16px] mb-6'>Direct Pay using Wallets</p>

                <div className="flex flex-col gap-4 pay-buy-btn" >
                   
                    {PaymentType === 'cardpay' &&
                        <CardPaymentForm />
                    }
                     {PaymentType !== 'cardpay' &&
                    <PayWithCardButton />
                }

                </div>

            </div>
        </div>
        </>
    )
}

export default SelectPayment
