import React from "react";
import BannerSec from "../home-components/BannerSec";
import DiscountParkSec from "../home-components/DiscountParkSec";
import Aminities from "../home-components/Aminities";
import { Helmet } from "react-helmet";


const Home = () => {
  return <>
    <Helmet>
      <title>Affordable LGA Airport Parking | Discount Parking NY</title>
      <meta
        name="description"
        content="Save on Affordable LGA Airport Parking NY with our discounted rates. Convenient and affordable options for stress-free travel. Book Discount Airport Parking at LGA!"
      />
      <meta
        name="keywords"
        content="Discount Airport Parking at LGA, Affordable LGA Airport Parking NY, affordable airport parking near LGA NY"
      />
    </Helmet>

    <BannerSec />

    <DiscountParkSec />
    <Aminities />
  </>;
};

export default Home;
