import React from 'react'

const AirPortParkingInfoBox = ({icon, info}) => {
  return (
   <div className="airport-info-box">
    <img src={icon}/>
    <p>{info}</p>
   </div>
  )
}

export default AirPortParkingInfoBox
