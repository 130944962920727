import React from 'react'
import AmentiesBox from './AmentiesBox'
import Am1 from '../assets/images/am1.svg'
import Am2 from '../assets/images/am2.svg'
import Am3 from '../assets/images/am3.svg'
import Am4 from '../assets/images/am4.svg'
import Am5 from '../assets/images/am5.svg'
import Am6 from '../assets/images/am6.svg'
import Am7 from '../assets/images/am7.svg'
import { Fade } from 'react-reveal';

const Aminities = () => {
    return (
        <section className='amit-section pb-20'>
            <div className="container mx-auto   ">
                <div className="py-[40px] px-[15px] bg-[#1C1C1C] rounded-[10px]">
                <Fade delay={200}>
                    <h3>Amenities</h3>  
                    </Fade>
                

                <div className="md:flex grid grid-cols-1 flex-wrap gap-3 justify-center mt-5">
                <Fade delay={200}>
                    <AmentiesBox icon={Am1} text='handicap parking'/>
                    </Fade>
                    <Fade delay={200}>
                    <AmentiesBox icon={Am2} text='⁠complimentary shuttle'/>
                    </Fade>
                    <Fade delay={200}>
                    <AmentiesBox icon={Am3} text='free cancellation before checkin time'/>
                    </Fade>
                    <Fade delay={200}>
                    <AmentiesBox icon={Am4} text='gated facility'/>
                    </Fade>
                    <Fade delay={200}>
                    <AmentiesBox icon={Am5} text='security cameras'/>
                    </Fade> 
                       <Fade delay={200}>

                    <AmentiesBox icon={Am6} text='⁠luggage assistance'/>
                    </Fade>
                    <Fade delay={200}>
                    <AmentiesBox icon={Am7} text='⁠valet service'/>
                    </Fade>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Aminities
