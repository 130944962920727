import React, { useEffect, useState } from "react";


import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PublicHeader from "./components/PublicHeader";
import SnackBar from "./components/SnackBar";
import AdminHeader from "./components/AdminHeader";
import { AuthContext } from "./AuthContext";

import AdminLoginPage from "./pages/AdminLoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Book from "./pages/Book";
import PaymentPage from "./pages/PaymentPage";
import CompletePage from "./pages/CompletePage";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import BlogInner from "./Blogs/BlogInner1";
import ContactPage from "./pages/ContactPage";
import Dashboard from "./admin/Dashboard";
import Coupons from "./admin/Coupons";
import TaxesFees from "./admin/TaxesFees";
import Packages from "./admin-modals/Packages";
import Reservation from "./admin/Reservation";
import Profile from "./admin/Profile";
import TopHeader from "./admin-components/TopHeader";
import OperatingHours from "./admin/OperatingHours";
import AdminFaq from "./admin/Faq";
import EmailNotes from "./admin/EmailNotes";
import Calender from "./admin/Calender";
import Refund from "./pages/Refund";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import BlogInner1 from "./Blogs/BlogInner1";
import BlogInner2 from "./Blogs/BlogInner2";
import BlogInner3 from "./Blogs/BlogInner3";
import BlogInner4 from "./Blogs/BlogInner4";
import BlogInner5 from "./Blogs/BlogInner5";
import PaymentFailed from "./pages/PaymentFailed";
import ReactGA from 'react-ga';
const TRACKING_ID = "AW-10995236960";
ReactGA.initialize(TRACKING_ID);

function renderHeader(role) {
  // switch (role) {
  //   case "admin":
  //     return <AdminHeader />;

  //   default:
  //   // return <PublicHeader />;
  // }
  return <PublicHeader />;
}

function renderRoutes(role) {
  // switch (role) {
  //   case "admin":
  //     return (
  //       <Routes>

  //         <Route exact path="/dashboard" element={<Dashboard />}></Route>
  //         <Route exact path="/" element={<Dashboard />}></Route>
  //         <Route exact path="/coupons" element={<Coupons />}></Route>
  //         <Route exact path="/taxes_fees" element={<TaxesFees />}></Route>
  //         <Route exact path="/pricing" element={<Packages />}></Route>
  //         <Route exact path="/reservations" element={<Reservation />}></Route>
  //         <Route exact path="/profile" element={<Profile />}></Route>
  //         <Route exact path="/business_information" element={<OperatingHours />}></Route>
  //         <Route exact path="/faq" element={<AdminFaq />}></Route>
  //         <Route exact path="/email_note" element={<EmailNotes />}></Route>
  //         <Route exact path="/calendar" element={<Calender />}></Route>
  //         <Route path="*" exact element={<Dashboard />}></Route>
  //         {/* <Route path="/" exact element={<Home />}></Route>
  //         <Route path="/airport-parking" exact element={<Home />}></Route>
  //         <Route path="/book" exact element={<Book />}></Route>
  //         <Route path="/payment" exact element={<PaymentPage />}></Route>
  //         <Route path="/reservation_Complete" exact element={<CompletePage />}></Route>
  //         <Route path="/faq" exact element={<Faq />}></Route>
  //         <Route path="/blog" exact element={<Blog />}></Route>
  //         <Route path="/blog_detail1" exact element={<BlogInner1 />}></Route>
  //         <Route path="/blog_detail2" exact element={<BlogInner2 />}></Route>
  //         <Route path="/blog_detail3" exact element={<BlogInner3 />}></Route>
  //         <Route path="/blog_detail4" exact element={<BlogInner4 />}></Route>
  //         <Route path="/blog_detail5" exact element={<BlogInner5 />}></Route>
  //         <Route path="/contact" exact element={<ContactPage />}></Route>
  //         <Route path="/refund" exact element={<Refund />}></Route>
  //         <Route path="/terms_&_condition" exact element={<Terms />}></Route>
  //         <Route path="/privacy_&_policy" exact element={<Privacy />}></Route>
  //         <Route path="/payment_failed" exact element={<PaymentFailed />}></Route> */}


  //         <Route path="*" exact element={<NotFoundPage />}></Route>

  //         {/* <Route
  //           path="*"
  //           element={<Navigate to="/dashboard" /> || <NotFoundPage />}
  //         ></Route> */}
  //       </Routes>
  //     );

  //   default:
  //     return (
  //       <Routes>
  //         <Route exact path="/" element={<AdminLoginPage />}></Route>
  //         <Route exact path="/login" element={<AdminLoginPage />}></Route>
  //         <Route path="*" exact element={<NotFoundPage />}></Route>
  //       </Routes>
  //     );
  // }


  return (
    <Routes>
      <Route path="/" exact element={<Home />}></Route>
      <Route path="/airport-parking" exact element={<Home />}></Route>
      <Route path="/book" exact element={<Book />}></Route>
      <Route path="/payment" exact element={<PaymentPage />}></Route>
      <Route path="/reservation_Complete" exact element={<CompletePage />}></Route>
      <Route path="/faq" exact element={<Faq />}></Route>
      <Route path="/blog" exact element={<Blog />}></Route>
      <Route path="/blog_detail1" exact element={<BlogInner1 />}></Route>
      <Route path="/blog_detail2" exact element={<BlogInner2 />}></Route>
      <Route path="/blog_detail3" exact element={<BlogInner3 />}></Route>
      <Route path="/blog_detail4" exact element={<BlogInner4 />}></Route>
      <Route path="/blog_detail5" exact element={<BlogInner5 />}></Route>
      <Route path="/contact" exact element={<ContactPage />}></Route>
      <Route path="/refund" exact element={<Refund />}></Route>
      <Route path="/terms_&_condition" exact element={<Terms />}></Route>
      <Route path="/privacy_&_policy" exact element={<Privacy />}></Route>
      <Route path="/payment_failed" exact element={<PaymentFailed />}></Route>
      <Route path="*" exact element={<NotFoundPage />}></Route>
    </Routes>
  );
}

function Main() {





  const { state } = React.useContext(AuthContext);

  return (
    <div className="h-full w-full">
      {/* <>
        {state.role === 'admin' ? (
          <>
            <div className="flex w-full">
              {renderHeader(state.role)}
              <div className="page-content w-full md:px-[40px] px-[15px] bg-[#F9F9F9]">
                <TopHeader />
                {renderRoutes(state.role)}
              </div>
            </div>
          </>
        ) : (
          <>
            {renderHeader(state.role)}
            {renderRoutes(state.role)}
            <Footer />
          </>
        )}
      </> */}

      {renderHeader()}
      {renderRoutes()}
      <Footer />



      <SnackBar />

    </div>
  );
}

export default Main;
