import React from 'react'
import { useState } from 'react'

const FaqBox = ({ Q, A }) => {


    const [showAns, setShowAns] = useState(false)


    return (
        <div className={`faq-box ${showAns? 'show': ''}`}
            onClick={() => {
                setShowAns(!showAns)
            }}
        >
            <h3>{Q}</h3>
            <p>{A}</p>
            <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H12M12 12H19M12 12V5M12 12V19" stroke="#3C3A3F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            </span>
        </div>
    )
}

export default FaqBox
