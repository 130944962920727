import React from 'react'

const AmentiesBox = ({icon, text}) => {
  return (
    <div className="amient-box">
        <div className="amient-img">
            <img src={icon} alt=''/>
        </div>
        <p>{text}</p>
    </div>
  )
}

export default AmentiesBox
