import React from 'react'
import { NavLink } from 'react-router-dom'

const PaymentFailed = () => {
    return (

        <div className="payAlert gap-5">
            <h3 className=' text-red-500 text-[40px] font-[800]'>Payment Failed X</h3>
            <p>Your payment has been failed please try with another Method Thanks!</p>
            <div className="flex justify-center gap-2">
                <NavLink to="/payment">Try again</NavLink>
                <NavLink to="/">Back to Home</NavLink>
            </div>
        </div>

    )
}

export default PaymentFailed
