import React, { forwardRef, useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";

import "../../node_modules/react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  DATE_FORMAT,
  ONLY_DATE_FORMAT,
  ONLY_TIME_FORMAT,
  getDateFromString,
  isValidDate,
} from "../../utils/utils";

const DataPickerButton = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    {value ? value : "Select date"}
  </button>
));
const SearchFieldBox = ({
  type,
  placeholder,
  label,
  edit,
  value,
  getDate,
  noPast,
  isCheckout,
  prevDate,
  startWorikingHours,
  endWorkingHours,
}) => {
  const [dateValue, onChange] = useState(
    isCheckout ? moment().add(1, "hour").toDate() : new Date()
  );

  const [startingHours, setStartingHours] = useState(undefined);
  const [endingHours, setEndingHours] = useState(undefined);
  useEffect(() => {
    setStartingHours(startWorikingHours);
    setEndingHours(endWorkingHours);
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const isMobile = window.innerWidth < 700;
  return (
    <div className={`field-box  ${edit ? "editable-field" : ""} `}>
      <label>{label}</label>
      {/* <input value={value} type={type} placeholder={placeholder ? placeholder : ''} /> */}

      {type === "datetime-local" ? (
        <DatePicker
          withPortal={isMobile}
          showTimeSelect
          minDate={new Date()}
          selected={prevDate ? getDateFromString(prevDate).toDate() : startDate}
          isValidDate={(date) => {
            const now = moment().startOf("day"); // Get the current date and set time to the start of the day

            return date.isSameOrAfter(now);
          }}
          value={prevDate}
          // minTime={startingHours}
          // maxTime={endingHours}

          // minTime={moment("04:00 am", "hh:mm a").toDate()}
          // maxTime={moment("10:00 pm", "hh:mm a").toDate()}
          dateFormat={ONLY_DATE_FORMAT}
          timeFormat={ONLY_TIME_FORMAT}
          customInput={isMobile ? <DataPickerButton /> : undefined}
          onChange={(selectedDate) => {
            setStartDate(selectedDate);
            const dateObject = selectedDate._isAMomentObject
              ? selectedDate.toDate()
              : selectedDate;
            onChange(dateObject);
            const formattedDateTime = moment(selectedDate).format(DATE_FORMAT);
            getDate(formattedDateTime);
          }}
        />
      ) : (
        <input
          value={value}
          type={type}
          placeholder={placeholder ? placeholder : ""}
        />
      )}
    </div>
  );
};

export default SearchFieldBox;
