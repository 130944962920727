import React, { useContext, useEffect } from "react";
import { useState } from "react";
import SearchFieldBox from "./SearchFieldBox";
import { NavLink } from "react-router-dom";
import { useCallback } from "react";
import { GlobalContext } from "../globalContext";

import { getAirports } from "../service/UserApi";
import {
  ONLY_DATE_FORMAT,
  checkHourDIffrence,
  getDateFromString,
  getEndWokingHour,
  getStartWokingHour,
  isValidDate,
} from "../../utils/utils";
import moment from "moment";
import reservationActions from "../actions/ReservationActions";
import { setHours, setMinutes } from "date-fns";

const SearchBox = () => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [operatingModal, setOperatingModal] = useState(false);

  const [nameAirport, setNameAirport] = useState([]);

  const { state } = React.useContext(GlobalContext);

  const { reservation, businessInfo } = state;
  // useEffect(() => {
  //   globalDispatch(
  //     reservationActions.updateReservation({
  //       airport: null,
  //       checkin: "",
  //       checkout: "",
  //       package: null,
  //       carDetails: null,
  //       reservationResponse: { data: null },
  //       payment: {
  //         depositAmount: 0,
  //         discountAmount: 0,
  //         subtotal: 0,
  //         tax: 0,
  //         total: 0,
  //       },
  //     })
  //   );
  // },[]);
  let canBooknow =
    isValidDate(getDateFromString(checkInDate), businessInfo) &&
    isValidDate(getDateFromString(checkOutDate), businessInfo) &&
    checkHourDIffrence(
      getDateFromString(checkInDate),
      getDateFromString(checkOutDate)
    );
  const showHourDiffMessage = !checkHourDIffrence(
    getDateFromString(checkInDate),
    getDateFromString(checkOutDate)
  );
  const getData = async () => {
    const airportName = await getAirports();
    globalDispatch(reservationActions.updateAirPort(airportName[0]));
    setTimeout(() => {
      setNameAirport(airportName);
    }, 200);
  };

  React.useEffect(() => {
    getData();
    globalDispatch({
      type: "CHECKINTIME",
      payload: {
        checkInTime: checkInDate,
      },
    });
    globalDispatch({
      type: "CHECKOUTTIME",
      payload: {
        checkOutTime: checkOutDate,
      },
    });

    globalDispatch({
      type: "AIRPORT",
      payload: {
        airportName: nameAirport?.length
          ? nameAirport[0].name
          : "SELECT AIRPORT",
      },
    });
    if (checkInDate) {
      globalDispatch(reservationActions.updateCheckin(checkInDate));
    }
    if (checkOutDate) {
      globalDispatch(reservationActions.updateCheckout(checkOutDate));
    }
    if (checkInDate && checkOutDate) {
      const startDate = moment(checkInDate,ONLY_DATE_FORMAT);
      const endDate =moment(checkOutDate,ONLY_DATE_FORMAT);
      const days = endDate.diff(startDate, "days")+1;
      // alert(startDate+", "+endDate+", "+days)
      globalDispatch(reservationActions.updateDays(days));
    }
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    // if (reservation.checkin) {
    //   setCheckInDate(reservation.checkin);
    // }
    // if (reservation.checkout) {
    //   setCheckOutDate(reservation.checkout);
    // }
  }, []);

  const formatTime = (timeString) => {
    if (!timeString) {
      // console.error('Invalid time string:', timeString);
      return "";
    }
    return moment(timeString, "HH:mm:ss").format("hh:mm a");
  };

  return (
    <div className="search-box-holder bg-[white] rounded-[20px] p-4">
      {operatingModal && (
        <div className="operating-modal">
          <div
            className="operating-modal-overlay"
            onClick={() => {
              setOperatingModal(false);
            }}
          ></div>
          <div className="operating-hours">
            <h5 className="text-[#F38030] text-[16px] font-[500] mb-4">
              Operating Hours
            </h5>
            <div className="grid grid-cols-2">
              <p>Sunday</p>
              <p>
                {formatTime(businessInfo?.sunday_start_time)} -{" "}
                {formatTime(businessInfo?.sunday_end_time)}
              </p>

              <p>Monday</p>
              <p>
                {formatTime(businessInfo?.monday_start_time)} -{" "}
                {formatTime(businessInfo?.monday_end_time)}
              </p>

              <p>Tuesday</p>
              <p>
                {formatTime(businessInfo?.tuesday_start_time)} -{" "}
                {formatTime(businessInfo?.tuesday_end_time)}
              </p>

              <p>Wednesday</p>
              <p>
                {formatTime(businessInfo?.wednesday_start_time)} -{" "}
                {formatTime(businessInfo?.wednesday_end_time)}
              </p>

              <p>Thursday</p>
              <p>
                {formatTime(businessInfo?.thursday_start_time)} -{" "}
                {formatTime(businessInfo?.thursday_end_time)}
              </p>

              <p>Friday</p>
              <p>
                {formatTime(businessInfo?.friday_start_time)} -{" "}
                {formatTime(businessInfo?.friday_end_time)}
              </p>

              <p>Saturday</p>
              <p>
                {formatTime(businessInfo?.saturday_start_time)} -{" "}
                {formatTime(businessInfo?.saturday_end_time)}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex md:flex-row flex-col gap-4">
        <div className="md:max-w-[45%]  max-w-[100%] w-full">
          {/* <p>{JSON.stringify(reservation)}</p> */}
          {/* {nameAirport.map((item) => (
                        <>
                            <p> {item.name}</p>
                        </>
                    ))} */}
          {/* <SearchFieldBox label='Airport' type='text' value="LGA - LaGuardia" placeholder="Search Nearest Airport" /> */}
          <div className={`field-box `}>
            <label>Airport</label>
            <input
              type="text"
              readOnly
              placeholder=""
              value={
                nameAirport?.length ? nameAirport[0].name : "SELECT AIRPORT"
              }
            />
          </div>
        </div>
        <div className="md:max-w-[27.5%] max-w-[100%] w-full">

        

          <SearchFieldBox
            getDate={setCheckInDate}
            label="Check In"
            type="datetime-local"
            startWorikingHours={getStartWokingHour(getDateFromString(checkInDate),businessInfo)}
            endWorkingHours={getEndWokingHour(getDateFromString(checkInDate),businessInfo)}
           
           
            prevDate={checkInDate}
          />
          {
            <p className="text-[16px]  text-red-500 flex items-center gap-2">
              {isValidDate(getDateFromString(checkInDate), businessInfo) ? (
                ""
              ) : checkInDate == "" ? (
                ""
              ) : (
                <>
                  We are not available at this{" "}
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setOperatingModal(true);
                    }}
                  >
                    {" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>{" "}
                  </span>{" "}
                </>
              )}
            </p>
          }
        </div>
        <div className="md:max-w-[27.5%] max-w-[100%] w-full">
          <SearchFieldBox
            isCheckout
            noPast={true}
            getDate={setCheckOutDate}
            label="Check Out"
            type="datetime-local"
            prevDate={checkOutDate}
          />
          {
            <p className="text-[16px]  text-red-500 flex items-center gap-2">
              {isValidDate(getDateFromString(checkOutDate), businessInfo) ? (
                ""
              ) : checkOutDate == "" ? (
                ""
              ) : (
                <>
                  We are not available at this this{" "}
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setOperatingModal(true);
                    }}
                  >
                    {" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>{" "}
                  </span>{" "}
                </>
              )}
            </p>
          }
        </div>
      </div>
      <NavLink
        to="/Book"
        className={` ${
          canBooknow ? "" : "disabled opacity-5"
        } transition-3 w-full book-area-btn mt-4`}
      >
        {showHourDiffMessage ? (
          "CheckOut Time Should be 1 hour greater then CheckIn Time"
        ) : (
          <>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z"
                fill="white"
                fill-opacity="0.25"
              />
              <path
                d="M5.625 9.25L9.2425 12.8675C9.27766 12.9026 9.32531 12.9223 9.375 12.9223C9.42469 12.9223 9.47234 12.9026 9.5075 12.8675L19.375 3"
                stroke="white"
                stroke-width="1.2"
              />
            </svg>
            Book Now
          </>
        )}
      </NavLink>
    </div>
  );
};

export default SearchBox;
