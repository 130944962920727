import React from 'react'
import { NavLink } from 'react-router-dom'

const BlogCard = ({ featuredImg, tag, title, date,page, desc }) => {
    return (
        <>
            <NavLink to={page} className="blog-card-holder">
                <div className="blog-img-holder">
                    <img src={featuredImg} alt='' />
                    <span>{tag}</span>
                </div>
                <h3>{title}</h3>
                <h6>{date}</h6>
                <p>{desc}</p>
            </NavLink>
        </>
    )
}

export default BlogCard
