import moment from "moment";

export function isValidDate(currentDate, workingData) {
  if (!moment.isMoment(currentDate)) {
    return false;
  }

  if (workingData == null) return false;
  const dayName = currentDate.format("dddd").toLowerCase(); // e.g., "monday"
  const cDateStr = currentDate.format("YYYY-MM-DD");
  const startTime = moment(
    cDateStr + " " + workingData[`${dayName}_start_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const endTime = moment(
    cDateStr + " " + workingData[`${dayName}_end_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const now = moment();
  if (currentDate.isSame(now, "day") && currentDate.isBefore(now)) {
    return false;
  }
  return currentDate.isBetween(startTime, endTime, null, "[]");
}

export function getStartWokingHour(currentDate, workingData) {
  if (!moment.isMoment(currentDate)) {
    return false;
  }

  const dayName = currentDate.format("dddd").toLowerCase(); // e.g., "monday"
  const cDateStr = currentDate.format("YYYY-MM-DD");
  const startTime = moment(
    cDateStr + " " + workingData[`${dayName}_start_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  return startTime.toDate();
  // const endTime = moment(
  //   cDateStr + " " + workingData[`${dayName}_end_time`],
  //   "YYYY-MM-DD HH:mm:ss"
  // );
  // const now = moment();
  // if (currentDate.isSame(now, 'day') && currentDate.isBefore(now)) {
  //   return false;
  // }
  // return currentDate.isBetween(startTime, endTime, null, "[]");
}

export function getEndWokingHour(currentDate, workingData) {
  if (!moment.isMoment(currentDate)) {
    return false;
  }

  const dayName = currentDate.format("dddd").toLowerCase(); // e.g., "monday"
  const cDateStr = currentDate.format("YYYY-MM-DD");
  const startTime = moment(
    cDateStr + " " + workingData[`${dayName}_start_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const endTime = moment(
    cDateStr + " " + workingData[`${dayName}_end_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  return endTime.toDate();

  // const now = moment();
  // if (currentDate.isSame(now, 'day') && currentDate.isBefore(now)) {
  //   return false;
  // }
  // return currentDate.isBetween(startTime, endTime, null, "[]");
}

export function checkHourDIffrence(checkInDate, checkOutDate) {
  if (!moment.isMoment(checkInDate) || !moment.isMoment(checkOutDate)) {
    return false; // Invalid dates
  }

  const difference = moment.duration(checkOutDate.diff(checkInDate));
  const hoursDiff = difference.asHours();

  return hoursDiff >= 1;
}
export const ONLY_DATE_FORMAT = "MM-DD-YYYY";
export const ONLY_TIME_FORMAT = "hh:mm a";
export const DATE_FORMAT = ONLY_DATE_FORMAT + " " + ONLY_TIME_FORMAT;

export const getDateFromString = (strDate) => {
  return moment(strDate, DATE_FORMAT);
};
export function getDatesBetween(startDate, eDate) {
  const dates = [];
  const currentDate = moment(startDate, ONLY_DATE_FORMAT);
  const endDate = moment(eDate, ONLY_DATE_FORMAT);
  // console.log({currentDate,endDate});
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.clone().toDate()); // Clone to create new objects
    currentDate.add(1, "days");
  }

  return dates;
}
