import React from 'react'
import ContactUs from '../contact-us-component/ContactUs'
import { Helmet } from 'react-helmet'

const ContactPage = () => {
    return (
        <div className="py-20 bg-[#FAFAFA] ">
        <Helmet>
          <title>Affordable Parking at LaGuardia Airport NY</title>
          <meta
            name="description"
            content="Find cheap parking at LaGuardia Airport NY. Save on Cheap Parking LGA Airport NY fees and ensure a hassle-free travel experience with our parking solutions."
          />
          <meta
            name="keywords"
            content="cheap parking at LaGuardia Airport NY, Cheap Parking LGA Airport NY"
          />
        </Helmet>
            <div className="container mx-auto">
                <h3 className='text-[#3C3A3F] text-[46px] font-[700]'>Contact Us</h3>
                {/* <p className='text-[#3C3A3F] text-[12px]'>Lorem ipsum dolor sit amet consectetur. Faucibus etiam tempus lorem condimentum nibh pretium hendrerit. Nec aliquam pulvinar risus purus eleifend aliquet.</p> */}

                <div className="w-full max-w-[500px] mt-10 ">
                    <ContactUs/>
                </div>
            </div>
        </div>
    )
}

export default ContactPage
