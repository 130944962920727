import React, { useContext, useEffect } from "react";
import TopPageDetail from "../complete-component/TopPageDetail";
import ReservationDetails from "../complete-component/ReservationDetails";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createReservation } from "../service/UserApi";
import { GlobalContext } from "../globalContext";
import AdminLogo from "../assets/images/logo.png";
import reservationActions from "../actions/ReservationActions";

const CompletePage = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,

    });
  };




  const [loading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  // const [stripeData, setStripeData] = useState(null);
  // const [reservationResponse, setreservationResponse] = useState(null);
  const [searchParams] = useSearchParams();

  const { state, dispatch } = useContext(GlobalContext);
  const { reservation } = state;
  const {
    reservationResponse,
    stripeData,
    package: selectedPackage,
  } = reservation;
  const { days } = reservation;

  const { isResCompleted } = reservation;

  const [shouldCreateReservation, setShouldCreateReservation] = useState(false);


  useEffect(() => {
    scrollToTop()
  }, []);

  useEffect(() => {
    const paymentIntent = searchParams.get("payment_intent");
    if (reservation.checkin && paymentIntent) {
      getPaymentIntent(paymentIntent);
    }
    if (!paymentIntent) setIsLoading(false);
  }, [reservation.checkin]);

  useEffect(() => {
    const paymentIntent = searchParams.get("payment_intent");
    if (stripeData && paymentIntent) {
      if (shouldCreateReservation) {
        createReservation(
          reservation,
          stripeData,
          paymentIntent,
          (success, response) => {
            // console.log({ response });
            if (success) {
              // setreservationResponse(response);
              dispatch(reservationActions.updateReservationResponse(response));
              dispatch(reservationActions.setIsCompleted("true"));
              // alert(JSON.stringify(response)
              window.history.replaceState(null, "", "/reservation_Complete");
            }
            setIsLoading(false);
          }
        );
      }
    }
  }, [stripeData, shouldCreateReservation]);
  function extractPaymentDetails(jsonResponse) {
    // Assuming jsonResponse is a string, parse it into a JavaScript object
    const parsedResponse = jsonResponse;
    // payment_method_options
    if (parsedResponse.status == "succeeded") {
      const cardInfo =
        parsedResponse.charges.data[0]?.payment_method_details?.card;
      const paymentStatus = parsedResponse?.charges?.data[0].status;
      const receipt_url = parsedResponse.charges?.data[0]?.receipt_url;
      const amount = parsedResponse.amount;
      const currency = parsedResponse.currency;

      return {
        cardInformation: {
          brand: cardInfo?.brand || "",
          last4: cardInfo?.last4 || "",
          exp_month: cardInfo?.exp_month || "",
          exp_year: cardInfo?.exp_year || "",
          country: cardInfo?.country || "",
        },
        paymentStatus: paymentStatus,
        receipt_url: receipt_url,
        amount: amount,
        currency: currency,
      };
    } else {
      return null;
    }
  }

  // Example Usage (assuming you have the JSON response string in 'stripeResponse'):

  const getPaymentIntent = async (payment_intent) => {
    const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      "Bearer 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"
      // "Bearer sk_test_51EvVusHvHB9LjwtDK0SVhHLBJNadpgcdXZRVy6nTR7oJtX1w02PIdP2rlisJY3RjvI4hzPWOhJYgWOVfBvbPYINb00JGP0Q7Cc"
      
    );

    const urlencoded = new URLSearchParams();
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.stripe.com/v1/payment_intents/" + payment_intent,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const data = extractPaymentDetails(result);
        if (data != null){
          // console.log({ reservation, data });
          dispatch(reservationActions.updateStripeData(data));
          setShouldCreateReservation(true);
        }else{
          navigate('/payment_failed')
        }
          // setStripeData(data);
        
        // createReservation(
        //   reservation,
        //   data,
        //   payment_intent,
        //   (success, response) => {
        //     console.log({ response });
        //     if (success) {
        //       setreservationResponse(response);
        //     }
        //   }
        // );
      })
      .catch((error) => console.error(error));
  };

  const [activeContent, setActiveContent] = useState("summary");

  // const handleTabClick = (tab) => {
  //   setActiveContent(tab);
  // };

  const handleTabClick = (tabName) => {
    setActiveContent(tabName);

    // Scroll to the corresponding section
    const section = document.getElementById(tabName);
    section.scrollIntoView({ behavior: "smooth" });
  };

  // alert(reservationResponse)

  if (loading)
    return (
      <div className="flex items-center flex-col gap-4 justify-between min-h-fit complete-loading">
      <img src={AdminLogo} alt="" />
      <p className="md:text-[23px] sm:text-[18px] text-[16px] text-center text-[red] font-[700]">Please wait while we process your reservation. May take up to 10 seconds</p>
    </div>
    );
  return (
    <>
      {/* {selectedPackage == null &&
        (

          <div className="payAlert">
            <p>Select Package First to
              create a new reservation!</p>
            <NavLink to='/'>Select Package</NavLink>
          </div>
        )

      } */}
      {selectedPackage == null ? (
        <div className="payAlert">
          <p>
            Reservation completed successfully, Please navigate to home screen
            to create a new reservation!
          </p>
          <NavLink to="/">Go to home</NavLink>
        </div>
      ) : (
        <ReservationDetails details={reservationResponse} />
      )}
    </>
  );
};

export default CompletePage;
