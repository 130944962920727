import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from 'react-router-dom'
import { GlobalContext } from '../globalContext';
import reservationActions, {
} from "../actions/ReservationActions";



const SignupPopup = ({ isSignup,scrollToTop }) => {



    const { dispatch: globalDispatch } = React.useContext(GlobalContext);



    const schema = yup
        .object({
            name: yup.string().required().matches(/^[A-Za-z\s]+$/, 'Name must contain only letters and spaces'),
            // carMake: yup.string().required().matches(/^[A-Za-z\s]+$/, 'Car make must contain only letters and spaces'),
            // model:  yup.string().required().matches(/^[A-Za-z\s]+$/, 'Model must contain only letters and spaces'), 
            // color: yup.string().required().matches(/^[A-Za-z\s]+$/, 'Color must contain only letters and spaces'),
            phone: yup
            .string()
            .required('Phone number is required')
            .min(10, 'Phone number must be 10 digits')  // Add this line
            .max(10, 'Phone number must be 10 digits'),
            email: yup.string().email().required(),
        })
        .required();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        scrollToTop()
        globalDispatch(reservationActions.updateCarDetails(data))
        isSignup(false)
    };



    return (
        <div className="popup-holder">
            <div className="popup-box">
                <h3 className='clash text-[#2B2B2B] md:text-[24px] text-[15px]'>Before you forget</h3>
                <p className='text-[#707070] text-[12px]'>*Mandatory field</p>

                <form className='md:mt-8 mt-3' onSubmit={handleSubmit(onSubmit)} >
                    <div className="flex flex-col md:gap-6 gap-3">
                        <div className="popup-field-box">
                            <label>Full Name</label>
                            <input
                                {...register("name")}
                                type='text' />
                            <p className="text-red-500 text-xs italic">
                                {errors.name?.message}
                            </p>
                        </div>
                        <div className="popup-field-box">
                            <label>Email</label>
                            <input
                                {...register("email")}
                                type='email' />
                            <p className="text-red-500 text-xs italic">
                                {errors.email?.message}
                            </p>
                        </div>
                        {/* <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-3">
                            <div className="popup-field-box">
                                <label>Car Make</label>
                                <input
                                    {...register("carMake")}
                                    type='text' />
                                <p className="text-red-500 text-xs italic">
                                    {errors.carMake?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Model</label>
                                <input
                                    {...register("model")}
                                    type='text' />
                                <p className="text-red-500 text-xs italic">
                                    {errors.model?.message}
                                </p>
                            </div>
                            <div className="popup-field-box">
                                <label>Color</label>
                                <input
                                    {...register("color")}
                                    type='text' />
                                <p className="text-red-500 text-xs italic">
                                    {errors.color?.message}
                                </p>
                            </div>
                        </div> */}
                        <div className="popup-field-box">
                            <label>Phone Number</label>
                            <input
                                {...register("phone")}
                                type='text' placeholder='Enter Your Phone Number' />
                            <p className="text-red-500 text-xs italic">
                                {errors.phone?.message &&
                                'Phone Number should contain 10 digits'
                                }
                            </p>
                        </div>

                        <button type="submit" className='bg-[#F38030] md:py-4 py-2 rounded-[10px] text-center text-[white] md:text-[16px] text-[12px] font-[600]' >
                            Continue
                        </button>
                    </div>
                </form>

                <p className='text-[#2B2B2B] text-[12px] md:mt-6 mt-3 md:pt-5 pt-2 border-t border-[#C4C4C4]'>
                    If you have any questions or concerns, contact us, email us at <NavLink className=' underline' to='mailto:support@parkndepart.io'>support@parkndepart.io</NavLink>
                </p>
            </div>
        </div>
    )
}

export default SignupPopup
