import React from 'react'
import BlogCard from '../Blogs/BlogCard'
import BlogImg from '../assets/images/blog-1.png'

const Blog = () => {
    return (
        <div className="py-20 bg-[#FAFAFA]">
            <div className="container mx-auto">
                <h3 className='text-[#3C3A3F] text-[46px] font-[700]'>Blogs</h3>
                {/* <p className='text-[#3C3A3F] text-[12px]'>Lorem ipsum dolor sit amet consectetur. Faucibus etiam tempus lorem condimentum nibh pretium hendrerit. Nec aliquam pulvinar risus purus eleifend aliquet.</p> */}


                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-3 mt-8">
                    <BlogCard
                        featuredImg={BlogImg}
                        tag='Topic'
                        page="/blog_detail1"
                        title="Navigating New York: A Complete Guide to Parking Near LaGuardia Airport"
                        date="05/01/2024"
                        desc="Introduction: In the bustling city of New York, every minute counts, especially when it comes to
                        catching a flight. At Park N Depart, we understand the importance of convenient and affordable
                        airport parking, particularly near LaGuardia Airport (LGA). In this comprehensive guide, we'll
                        explore everything you need to know about parking near LGA and why Park N Depart should be
                        your top choice.
                        "
                    />

                    <BlogCard
                        featuredImg={BlogImg}
                        tag='Topic'
                        page="/blog_detail2"
                        title="5 Tips for Stress-Free Airport Parking Experience at Park N Depart"
                        date="05/01/2024"
                        desc="Introduction: At Park N Depart, we understand that the journey begins before you even reach
                        the airport. That's why we're dedicated to providing travelers with a stress-free parking
                        experience near LaGuardia Airport (LGA). To help you make the most of your time with us,
                        we've compiled five tips to ensure a smooth and hassle-free parking experience at Park N
                    Depart
                        "
                    />

                    <BlogCard
                        featuredImg={BlogImg}
                        tag='Topic'
                        page="/blog_detail3"
                        title="Transparent Pricing: How Park N Depart Puts an End to Hidden Fees"
                        date="05/01/2024"
                        desc="Introduction: When it comes to airport parking, hidden fees can quickly sour the travel
                        experience. At Park N Depart, we believe in transparency and honesty, which is why we've
                        worked tirelessly with developers to revolutionize the parking industry. Our goal? To give
                        customers full control over their parking experience, including pricing based on vehicle size. Say
                        goodbye to hidden fees and hello to clarity and convenience with Park N Depart."
                    />

                    <BlogCard
                        featuredImg={BlogImg}
                        tag='Topic'
                        page="/blog_detail4"
                        title="Unlocking Seamless Airport Parking: A Comprehensive Guide by Park N Depart"
                        date="05/01/2024"
                        desc="Introduction: Embarking on a journey shouldn't be hindered by the stress of parking, especially
                        when it comes to catching a flight. Park N Depart is your trusted partner in navigating the
                        complexities of airport parking. In this guide, we unveil the secrets to seamless airport parking,
                        optimized for both convenience and search engine visibility.                        
                        "
                    />
                    
                    <BlogCard
                        featuredImg={BlogImg}
                        tag='Topic'
                        page="/blog_detail5"
                        title="The Ultimate New York LaGuardia (LGA) Airport Parking Guide"
                        date="05/01/2024"
                    desc="LaGuardia Airport (LGA) in Queens, New York, is one of the busiest U.S. Airports. For the remarkable $4 billion Terminal B renovation, the airport earned the title of ‘best new airport’ from organizations such as UNESCO’s Prix Versailles 2021, the Design-Build Institute of America, and the Urban Land Institute New York. Despite ongoing construction, travelers can navigate the changes with ease. Situated just over 9 miles northeast of Midtown Manhattan, LaGuardia serves as a vital hub for the city. Fortunately, parking is rarely an issue, with ample spaces available both at the airport and in nearby offsite garages and lots.                        
                        "
                    />







                </div>


            </div>
        </div>
    )
}

export default Blog
