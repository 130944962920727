import React from 'react'
import discountImg from '../assets/images/discount-title.svg'
import AirPortParkingInfoBox from './AirPortParkingInfoBox'
import { Fade } from 'react-reveal';

import InfoIco6 from '../assets/images/info-icon1.svg'
import InfoIco5 from '../assets/images/info-icon2.svg'
import InfoIco4 from '../assets/images/info-icon3.svg'
import InfoIco3 from '../assets/images/info-icon4.svg'
import InfoIco2 from '../assets/images/info-icon5.svg'
import InfoIco1 from '../assets/images/info-icon6.svg'

const DiscountParkSec = () => {
    return (
        <section className='discount-park-holder md:py-[80px] py-[60px] bg-[#F5F5F6]'>
            <div className="container mx-auto">
                <Fade delay={100}>
                <img className='max-w-[540px] w-full mx-auto' src={discountImg} />
                </Fade>
                <Fade delay={200}>
                <div className="grid md:grid-cols-3 grid-cols-2 gap-3 mt-10">
                    
                    <AirPortParkingInfoBox icon={InfoIco5} info="0.8 miles from the airport"/>
                    <AirPortParkingInfoBox icon={InfoIco6} info="Free Cancellation"/>
                    <AirPortParkingInfoBox icon={InfoIco2} info="Big Savings"/>
                    <AirPortParkingInfoBox icon={InfoIco4} info="Luggage assistance"/>
                    <AirPortParkingInfoBox icon={InfoIco1} info="Guaranteed Space with Reservations"/>
                    <AirPortParkingInfoBox icon={InfoIco3} info="Complimentary shuttle direct to your terminal"/>
                </div>
                </Fade>
            </div>
        </section>
    )
}

export default DiscountParkSec
