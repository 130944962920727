import React, { useEffect, useState } from 'react'
import FaqBox from '../faq-component/FaqBox'
import { getAllFaq } from '../service/UserApi'
import { Helmet } from 'react-helmet'
import ParkLogo from '../assets/images/logo.png'

const Faq = () => {

    const [faqBox, setFaqBox] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    const getData = async () => {
        const faqData = await getAllFaq();
        setFaqBox(faqData);
        setTimeout(() => {
            setIsLoading(false)
        }, 200);

    };

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <Helmet>
                <title>Comfortable Parking at LaGuardia Airport NY</title>
                <meta
                    name="description"
                    content="Secure and comfortable Airport parking NY. Enjoy peace of mind with our convenient airport parking at LaGuardia Airport NY."
                />
                <meta
                    name="keywords"
                    content="comfortable Airport parking NY, Parking at LaGuardia Airport NY"
                />
            </Helmet>
            <div className={`fixed flex items-center justify-center top-0 left-0 w-[100%] h-[100vh] bg-white page-loader ${!isLoading ? 'loaded' : ''}`}>
                <img className="md:w-[300px] w-[180px]" src={ParkLogo} />
            </div>
            <div className="py-20 bg-[#FAFAFA]">
                <div className="container mx-auto">
                    <h3 className='text-[#3C3A3F] md:text-[46px] text-[30px] font-[700]'>Frequently Asked Questions</h3>
                    {/* <p className='text-[#3C3A3F] text-[12px]'>Lorem ipsum dolor sit amet consectetur. Faucibus etiam tempus lorem condimentum nibh pretium hendrerit. Nec aliquam pulvinar risus purus eleifend aliquet.</p> */}


                    <div className="flex flex-col gap-3 mt-8">

                        {faqBox.map((item) => (
                            <FaqBox Q={item.question} A={item.answer} />
                        ))}

                        {/*                   

                    <FaqBox Q="How does Park N Depart compare in terms of cost?" A="Park N Depart provides affordable parking rates that are typically cheaper than parking
directly at the airport. We believe in offering value without compromising on quality service."/>
                    <FaqBox Q="Is there a shuttle service available?" A="Yes, Park N Depart offers a convenient shuttle service to and from LaGuardia Airport. Our
shuttles run regularly, ensuring you reach your terminal in a timely manner"/>
                    <FaqBox Q="Is Park N Depart located in a safe area?" A="Absolutely. Park N Depart prioritizes the safety and security of our customers and their
vehicles. Our facility is well-lit and equipped with security measures to give you peace of mind
during your trip.
"/>
                    <FaqBox Q="Are there any hidden fees I should be aware of?" A="No hidden fees here! Park N Depart believes in transparency, so what you see is what you
get. Our pricing is straightforward, with no surprises or hidden charges"/>


                    <FaqBox Q="How far in advance should I book my parking spot?" A="We recommend booking your parking spot as soon as your travel plans are confirmed to
ensure availability. However, we also accept last-minute bookings, subject to availability.
Booking in advance guarantees your spot and often comes with discounts."/>

                    <FaqBox Q="What are the late fees if I exceed my reservation period?" A="If you exceed your reservation period, late fees are calculated at $34.83 per calendar day.
However, you have the option to extend your reservation directly at the facility if needed. Please
note that modifications to reservations are not possible after check-in, so it's best to plan
accordingly.
"/>

                    <FaqBox Q="How can I cancel my reservation with Park N Depart?" A="To cancel your reservation, simply send an email through the 'Contact Us' page on our
website. Include your name and reservation ID for prompt processing. You can cancel free of
cost up to 24 hours before your check-in time. However, if you cancel after this period, you won't
be refunded your booking fee. Please note that cancellations are not possible after the reserved
check-in time has passed."/>

                    <FaqBox Q="Are there any additional charges I should be aware of?" A="Of course! At Park n Depart, we prioritize transparency and strive to make your experience seamless. Please note that for certain vehicle types, there are additional charges: SUVs incur an extra $5 per calendar day, oversize SUVs/minivans $10, and pick-up trucks $20. Additionally, there's an economic adjustment fee of $5.95 on every sale. This fee is necessary to sustain our commitment to offering discounted prices while managing increased operational expenses. Rest assured, it enables us to maintain the high quality of service you expect from us, ensuring your satisfaction throughout your journey." /> */}




                    </div>


                </div>
            </div>

        </>
    )
}

export default Faq
