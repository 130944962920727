import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Logo from '../assets/images/logo.png'
import { NavLink } from "react-router-dom";


export const PublicHeader = () => {

  const [shownav, setshownav] = useState(false);
  const location = useLocation();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  useEffect(() => {
    setshownav(false);
  }, [location.pathname]);


  const allowedRoutes = ['/',];
  const LoginPage = ['/admin/login',];

  const showUl = allowedRoutes.includes(location.pathname);

  const HideHeader = LoginPage.includes(location.pathname);


  return (
    <>
      {HideHeader ? '' : <div className={`main-header-wrapper py-4  top-0 ${showUl ? ' fixed' : 'bg-[#F38030] sticky'}  w-full ${scroll ? 'bg-[#F38030]' : ''} `}>
        <header className="max-w-[920px] w-full mx-auto rounded-[50px] py-2 px-4 bg-[white] absolute-header header-bar">
          <div className="flex  justify-between items-center">
            <NavLink to="/">
              <img className="max-w-[120px] head-logo w-full" src={Logo} alt="" />
            </NavLink>
            <nav className={`head-nav flex items-center gap-6 ${shownav ? 'show-menu' : ''}`}>
              <NavLink to="/faq">FAQ</NavLink>
              <NavLink to="/blog">Blog</NavLink>
              <NavLink to="/contact">Contact Us</NavLink>
            </nav>
            <div className="head-menu-btn" onClick={() => {
              setshownav(!shownav)
            }}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.33203 5H16.6654V6.66667H3.33203V5ZM3.33203 9.16667H16.6654V10.8333H3.33203V9.16667ZM3.33203 13.3333H16.6654V15H3.33203V13.3333Z" fill="black" />
              </svg>

            </div>
          </div>
        </header>
      </div>}

    </>

  );
};

export default PublicHeader;
