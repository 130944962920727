import React from 'react'
import moment from 'moment';
import { GlobalContext } from '../globalContext';
import { NavLink } from 'react-router-dom';

const Terms = () => {
    const currentDate = moment().format('MMMM D, YYYY'); // Format current date as desired
    const { dispatch, state } = React.useContext(GlobalContext);
    const { businessInfo } = state

  return (
    <div className="container mx-auto ">

    <div className="md:py-[70px] py-[40px]">
        <h3 className='md:text-[50px] text-[30px] font-[700] uppercase'>Terms and Conditions</h3>
        <div className="w-full key-box bg-[#EEEEEE] p-3 my-3">
            <h3 className="flex items-center gap-1 text-[#2B2B2B] text-[16px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16797 14.166H10.8346V9.16601H9.16797V14.166ZM10.0013 7.49935C10.2374 7.49935 10.4355 7.41935 10.5955 7.25935C10.7555 7.09935 10.8352 6.90157 10.8346 6.66601C10.8346 6.4299 10.7546 6.23213 10.5946 6.07268C10.4346 5.91324 10.2369 5.83324 10.0013 5.83268C9.76519 5.83268 9.56741 5.91268 9.40797 6.07268C9.24852 6.23268 9.16852 6.43046 9.16797 6.66601C9.16797 6.90213 9.24797 7.10018 9.40797 7.26018C9.56797 7.42018 9.76575 7.4999 10.0013 7.49935ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z" fill="#F38030" />
                </svg>
                Terms and Conditions for parkndepart.io

            </h3>
            <p>
        <strong>Effective Date:</strong> {currentDate}
      </p>

      {/* Introduction */}
      <p>
        Welcome to parkndepart.io. These Terms and Conditions govern your use of our website and services. By accessing or using parkndepart.io, you agree to abide by these Terms and Conditions.
      </p>

      {/* Section 1: Use of Services */}
      <p>
        <strong>1. Use of Services:</strong>
      </p>
      <ul>
        <li>
          parkndepart.io provides an online platform for users to search for and reserve parking spaces at partnered parking lots. You may use our website and services solely for personal and non-commercial purposes.
        </li>
        <li>
          You must be at least 18 years old to use our services. By using parkndepart.io, you represent that you are of legal age to enter into binding contracts.
        </li>
      </ul>

      {/* Section 2: Registration and Account */}
      <p>
        <strong>2. Registration and Account:</strong>
      </p>
      <ul>
        <li>
          In order to make reservations and access certain features of parkndepart.io, you may be required to create an account and provide personal information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </li>
        <li>
          You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to keep it accurate, current, and complete.
        </li>
      </ul>

      {/* Section 3: Parking Reservations */}
      <p>
        <strong>3. Parking Reservations:</strong>
      </p>
      <ul>
        <li>
          When you make a parking reservation through parkndepart.io, you are entering into a binding agreement with the parking lot operator. parkndepart.io acts solely as an intermediary and is not a party to the parking agreement.
        </li>
        <li>
          The terms and conditions of your parking reservation, including cancellation policies and refund eligibility, are governed by the policies of the individual parking lot. Please review the parking lot's policies carefully before making a reservation.
        </li>
      </ul>

      {/* Section 4: Prohibited Conduct */}
      <p>
        <strong>4. Prohibited Conduct:</strong>
      </p>
      <ul>
        <li>
          You agree not to use parkndepart.io for any unlawful or prohibited purpose, including but not limited to:
          <ul>
            <li>Violating any applicable laws or regulations.</li>
            <li>Interfering with or disrupting the operation of parkndepart.io or the servers or networks connected to our website.</li>
            <li>Engaging in any activity that could damage, disable, overburden, or impair parkndepart.io or interfere with any other party's use and enjoyment of our services.</li>
          </ul>
        </li>
      </ul>

      {/* Additional Sections... */}

      {/* Section 9: Contact Us */}
      <p>
        <strong>9. Contact Us:</strong>
      </p>
      <p>
        If you have any questions or concerns about these Terms and Conditions or your use of parkndepart.io, please contact us at <NavLink to={`tel:${businessInfo?.phone_number}`} className="font-[800] inline-flex items-center gap-2">

Phone: {businessInfo?.phone_number} 
</NavLink>
<NavLink to={`tel:${businessInfo?.email}`} className="inline-flex items-center gap-2 ml-2 font-[800]">

 Email: {businessInfo?.email}
</NavLink>.
      </p>

      {/* Acknowledgment */}
      <p>
        By using parkndepart.io, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, you may not use our website or services.
      </p>
        </div>
    </div>
</div>
  )
}

export default Terms
