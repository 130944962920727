import React, { useContext, useEffect } from "react";
import { useState } from "react";
import QuoteBox from "../book-componenet/QuoteBox";
import ReservationDetails from "../book-componenet/ReservationDetails";
import { GlobalContext } from "../globalContext";
import { useNavigate } from "react-router";

const Book = () => {



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,

    });
  };




  const [selectQuot, setSelectQuote] = useState(false);
const {state} = useContext(GlobalContext)
const {reservation} = state
const navigate = useNavigate()
  useEffect(() => {
        // if(!reservation?.checkin){
        //     navigate("/");
        // }
        
  scrollToTop()
  }, []);

  return (
    <div className="md:pt-[60px] pt-[60px] md:pb-20 pb-[60px] bg-[#FAFAFA]">
      <div className="container mx-auto">
        <div className="flex md:flex-row flex-col-reverse md:gap-20 gap-10">
          <div className="w-full md:max-w-[70%] max-w-[100%]">
            <QuoteBox isSelected={setSelectQuote} />
          </div>
          <div className="w-full md:max-w-[30%] max-w-[100%]">
            <ReservationDetails nextAble={selectQuot} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
