import React from 'react'
import PayCards from '../assets/images/paycards.svg'
import { GlobalContext } from '../globalContext';

const PayWithCardButton = () => {

    const { dispatch: gDispatch } = React.useContext(GlobalContext);

    return (
        <button type='button' className=' bg-[#F38030] hover:bg-[#b36f3f] flex text-[white] justify-center items-center w-full gap-2 text-[16px] font-[600] py-4 rounded-[10px]'
        onClick={() => {
            gDispatch({
              type: "PAY_TYPE",
              payload: { PaymentType: 'cardpay' },
            });
          }}
        >
            Proceed with Payment <img src={PayCards} alt='' />
        </button>
    )
}

export default PayWithCardButton
