import React from 'react'
import { GlobalContext } from "../globalContext";
import reservationActions, {
} from "../actions/ReservationActions";




const SelectQuoteBox = ({ vehicalImg, iconName, vehicalName, charges, isSelected, id, packageData }) => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);
    return (
        <div className="relative quot-box-holder"
            onClick={() => {

                isSelected(true)
                globalDispatch(reservationActions.updatePackage(packageData))
            }}
        >
            <input className='' type='radio' name='quote' />
            <div className="quot-box flex md:flex-row flex-col justify-between md:items-center bg-[#313131] rounded-[10px] px-6 md:py-0 py-4">
                <div className="flex items-center gap-6">
                    <img className='md:max-w-[80px] max-w-[55px] aspect-square w-full' src={vehicalImg} alt='' />
                    <h4 className='text-[#FFFFFF] md:text-[18px] text-[14px] uppercase font-[200] md:mb-0 mb-4 whitespace-break-spaces'>{vehicalName}</h4>
                </div>
                <div className="flex items-center gap-5 ">
                    <div className="flex flex-col md:text-right gap-0">
                        <h4 className='md:text-[24px] m-0 text-[17px] clash font-[600] text-[white]'>${charges}<span className='text-[15px]'>/Day</span></h4>
                        {iconName == 'valet' &&
                            <div className="flex items-center gap-[3px]">
                                <h5 className='text-[16px] clash  m-0 text-[#DA7630]'>$29.95</h5>
                                <h5 className='text-[12px]  m-0 text-[#DA7630]'>Orig.</h5>
                            </div>
                        }
                        {iconName == 'self-park' &&
                            <div className="flex items-center gap-[3px]">
                                <h5 className='text-[16px] clash  m-0 text-[#DA7630]'>$34.89</h5>
                                <h5 className='text-[12px]  m-0 text-[#DA7630]'>Orig.</h5>
                            </div>
                        }
                    </div>

                    {/* <span className='perday'>Per Day</span> */}

                    <div className="radio-box ml-auto"></div>
                </div>
            </div>
        </div>
    )
}

export default SelectQuoteBox
