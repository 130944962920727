import React from "react";
import SearchFieldBox from "../home-components/SearchFieldBox";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../globalContext";
import {
  ONLY_DATE_FORMAT,
  checkHourDIffrence,
  getDateFromString,
  isValidDate,
} from "../../utils/utils";
import moment from "moment";
import reservationActions from "../actions/ReservationActions";
import { getAirports } from "../service/UserApi";

const ReservationDetails = ({ nextAble }) => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [operatingModal, setOperatingModal] = useState(false);
  const [showReserveData, setShowReserveData] = useState(true);

  const setCheckInDate = (date) => {
    globalDispatch(reservationActions.updateCheckin(date));
  };
  const setCheckOutDate = (date) => {
    globalDispatch(reservationActions.updateCheckout(date));
  };

  const { state } = React.useContext(GlobalContext);
  const { reservation, businessInfo } = state;

  let [canBooknow, setCanBookNow] = useState(false);
  let [showHourDiffMessage, setShowHourDiffMessage] = useState(false);
  useEffect(() => {
    fetchAndSelectAirport();
  }, []);

  const fetchAndSelectAirport = async () => {
    const airportName = await getAirports();
    globalDispatch(reservationActions.updateAirPort(airportName[0]));
    setTimeout(() => {
      // setNameAirport(airportName);
    }, 200);
  };
  useEffect(() => {
    const isMobile = window.innerWidth < 700;
    setShowReserveData(isMobile ? false : true);

    try {
      const canBook =
        isValidDate(getDateFromString(reservation?.checkin), businessInfo) &&
        isValidDate(getDateFromString(reservation?.checkout), businessInfo) &&
        checkHourDIffrence(
          getDateFromString(reservation?.checkin),
          getDateFromString(reservation?.checkout)
        );
      setCanBookNow(canBook);
      const hourDiff = !checkHourDIffrence(
        getDateFromString(reservation?.checkin),
        getDateFromString(reservation?.checkout)
      );
      setShowHourDiffMessage(hourDiff);

      if (reservation?.checkin && reservation?.checkout) {
        const startDate = moment(reservation?.checkin, ONLY_DATE_FORMAT);
        const endDate = moment(reservation?.checkout, ONLY_DATE_FORMAT);
        const days = endDate.diff(startDate, "days") + 1;
        // alert(days)
        globalDispatch(reservationActions.updateDays(days));
      }
    } catch (error) {
      console.log(error);
    }
  }, [reservation?.checkin, reservation?.checkout, businessInfo]);

  useEffect(() => {
    const handleResize = () => {
      // setShowReserveData(window.innerWidth > 767);
    };

    // Initial check
    handleResize();

    // Event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTime = (timeString) => {
    if (!timeString) {
      // console.error('Invalid time string:', timeString);
      return "";
    }
    return moment(timeString, "HH:mm:ss").format("hh:mm a");
  };
  console.log({ reservation });

  return (
    <div className="w-full bg-white p-5 rounded-[10px] reservation-details-box">
      {operatingModal && (
        <div className="operating-modal">
          <div
            className="operating-modal-overlay"
            onClick={() => {
              setOperatingModal(false);
            }}
          ></div>
          <div className="operating-hours">
            <h5 className="text-[#F38030] text-[16px] font-[500] mb-4">
              Operating Hours
            </h5>
            <div className="grid grid-cols-2">
              <p>Sunday</p>
              <p>
                {formatTime(businessInfo?.sunday_start_time)} -{" "}
                {formatTime(businessInfo?.sunday_end_time)}
              </p>

              <p>Monday</p>
              <p>
                {formatTime(businessInfo?.monday_start_time)} -{" "}
                {formatTime(businessInfo?.monday_end_time)}
              </p>

              <p>Tuesday</p>
              <p>
                {formatTime(businessInfo?.tuesday_start_time)} -{" "}
                {formatTime(businessInfo?.tuesday_end_time)}
              </p>

              <p>Wednesday</p>
              <p>
                {formatTime(businessInfo?.wednesday_start_time)} -{" "}
                {formatTime(businessInfo?.wednesday_end_time)}
              </p>

              <p>Thursday</p>
              <p>
                {formatTime(businessInfo?.thursday_start_time)} -{" "}
                {formatTime(businessInfo?.thursday_end_time)}
              </p>

              <p>Friday</p>
              <p>
                {formatTime(businessInfo?.friday_start_time)} -{" "}
                {formatTime(businessInfo?.friday_end_time)}
              </p>

              <p>Saturday</p>
              <p>
                {formatTime(businessInfo?.saturday_start_time)} -{" "}
                {formatTime(businessInfo?.saturday_end_time)}
              </p>
            </div>
          </div>
        </div>
      )}
      <h4 className="text-center text-[16px] clash  reserve-title">
        Reservation Details{" "}
        <button
          className={`${!showReserveData ? "rotate-180" : ""}`}
          type="button"
          onClick={() => {
            setShowReserveData(!showReserveData);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_839_8192)">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(1 0 0 -1 0 20)"
                fill="#F2F2F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8828 6.61668C10.6484 6.38259 10.3307 6.25111 9.99943 6.25111C9.66818 6.25111 9.35047 6.38259 9.1161 6.61668L4.4011 11.33C4.16671 11.5645 4.03508 11.8825 4.03516 12.2141C4.03523 12.5456 4.16702 12.8635 4.40151 13.0979C4.63601 13.3323 4.95401 13.4639 5.28556 13.4639C5.61711 13.4638 5.93504 13.332 6.16943 13.0975L9.99943 9.26751L13.8294 13.0975C14.0651 13.3253 14.3808 13.4515 14.7085 13.4488C15.0363 13.4461 15.3498 13.3148 15.5817 13.0831C15.8136 12.8515 15.9452 12.538 15.9482 12.2103C15.9512 11.8825 15.8254 11.5667 15.5978 11.3308L10.8836 6.61584L10.8828 6.61668Z"
                fill="#F38030"
              />
            </g>
            <defs>
              <clipPath id="clip0_839_8192">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(1 0 0 -1 0 20)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </h4>
      {showReserveData && businessInfo != null && (
        <div className="flex flex-col gap-6 border-b-[2px] border-t-[2px] border-[#D6D6D6] pb-4 pt-2 mt-4 mb-1">
          <SearchFieldBox
            getDate={setCheckInDate}
            type="datetime-local"
            label="Check In"
            prevDate={reservation?.checkin}
            edit={true}
          />
          {reservation?.checkin != "" && (
            <p className="text-[16px]  text-red-500 flex items-center gap-2 mt-[-15px] mb-[-15px]">
              {isValidDate(
                getDateFromString(reservation?.checkin),
                businessInfo
              ) ? (
                ""
              ) : reservation?.checkin == "" ? (
                ""
              ) : (
                <>
                  We are not available at this{" "}
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setOperatingModal(true);
                    }}
                  >
                    {" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>{" "}
                  </span>{" "}
                </>
              )}
            </p>
          )}
          <SearchFieldBox
            getDate={setCheckOutDate}
            type="datetime-local"
            label="Check Out"
            prevDate={reservation?.checkout}
            edit={true}
          />
          {reservation?.checkout && (
            <p className="text-[16px]  text-red-500 flex items-center gap-2 mt-[-15px] mb-[-15px]">
              {isValidDate(
                getDateFromString(reservation?.checkout),
                businessInfo
              ) ? (
                ""
              ) : reservation?.checkout == "" ? (
                ""
              ) : (
                <>
                  We are not available at this{" "}
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setOperatingModal(true);
                    }}
                  >
                    {" "}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                        fill="#707070"
                      />
                    </svg>{" "}
                  </span>{" "}
                </>
              )}
            </p>
          )}
        </div>
      )}
      <p style={{ color: "red" }}>
        {" "}
        {showHourDiffMessage
          ? "Checkout time should be 1 hour greater then checkin time"
          : ""}
      </p>
      {nextAble && (
        <NavLink
          to="/payment"
          className={`w-full inner-book book-area-btn fader mt-4 ${canBooknow ? "" : "opacity-5 disabled pointer-events-none"
            } `}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z"
              fill="white"
              fill-opacity="0.25"
            />
            <path
              d="M5.625 9.25L9.2425 12.8675C9.27766 12.9026 9.32531 12.9223 9.375 12.9223C9.42469 12.9223 9.47234 12.9026 9.5075 12.8675L19.375 3"
              stroke="white"
              stroke-width="1.2"
            />
          </svg>
          Next
        </NavLink>
      )}
    </div>
  );
};

export default ReservationDetails;
