import moment from "moment";
import { BASE_URL, api } from "./api";
import { DATE_FORMAT } from "../../utils/utils";

export const getAllTaxes = async () => {
  const resp = await api.get("/taxes?airport_id=1");

  return resp.data;
};

export const getAllDeposit = async () => {
  const resp = await api.get("/settings/deposit_fee?airport_id=1");
  return resp.data;
};

export const getAllFaq = async () => {
  const resp = await api.get("/faq");
  return resp.data;
};

export const verifyCoupon = async (code) => {
  const resp = await api.post(`/coupon/verify?airport_id=1&code=${code}`);

  return resp.data;
};

export const getAirports = async () => {
  const resp = await api.get("/airports");

  return resp.data;
};

export const getOperatingTime = async () => {
  const resp = await api.get("/business-information");

  return resp.data;
};

export const getPackages = async () => {
  //   const myHeaders = new Headers();
  // myHeaders.append("Accept", "application/json");
  // myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjNoOGRkaWdkWlJrWVpvclNtdmhSWVE9PSIsInZhbHVlIjoiK0I4ZFd4SkJ2SUhiYlQ5cFgvK3NvbENFWk1nZ1BMV2Raa1ZiSEFnbU5nS2w2S0tGbFpQd1FIYWMwejkwUGRXdmwwTWo0NmxYdU1nT2E0RnR2OEdmUGNuNzh3cExFcmg3Z0xDMlhkSlZ6Tlpaek1oajJsWnZwVXVUVnpWQm4xcGsiLCJtYWMiOiI3NjI4MjRlZjk1OWVhNjllY2UxMTY5MjA4Y2Y5NWNmZTVlNmQxNWRkMTZiMDY4MmY0NDM4Y2VmYzZlNGM3NjNmIiwidGFnIjoiIn0%3D; park_n_depart_session=eyJpdiI6IkRMNEIzVk9Qc2pGMkdxOHZ0ZVduc1E9PSIsInZhbHVlIjoieC9pVVpsQ1J3UWtUbE5zOUxPUTVmTm4zNVNKSTlCYUFhY2hoaXh0d1JjVVpQN2xZcC94UHc5aFpLeEpMTE1TTW5mY2x4VkR1dkxlUWN1clhwR3NiS0ZZelFWa1Y5ZGE0d1BObjlITDU3T2tOYnp5WmdNQlBwa3JvVnFGWWJhRVMiLCJtYWMiOiJkMmU2ZTgxODFiNTgxNjg4M2YyY2VkMjhlNjZiODRkODcwNzI2N2FkZDlkMjkwYmQ0MjhmM2IxNjRkMjM1MTcwIiwidGFnIjoiIn0%3D");

  const resp = await api.get("/packages?airport_id=1");
  return resp.data;
};

export const AddRequest = (params, onResponse) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  let ResID = ""
  let ResParam = ''
  if (params.reservation_id) {
    ResID += `${params.reservation_id}`;
    ResParam += `&reservation_id=${params.reservation_id}`;
  }

  fetch(
    `https://parkndepart.io/backend/public/api/reservation/${ResID ? ResID : '0'}/cancel?name=${params.name}&email=${params.email}&subject=${params.subject}${ResParam}&reason=${params.reason}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((result) => {
      onResponse(true, result); // Success callback
    })
    .catch((error) => {
      onResponse(false, { error: error.message }); // Error callback
    });
};

export const createReservation = (data, transaction, paymentId, onResponse) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const {
    carDetails,
    airport,
    package: selectedPackage,
    checkin,
    checkout,
    payment,
  } = data;
  const formdata = new FormData();
  formdata.append("user_first_name", carDetails?.name);
  formdata.append("user_last_name", ".");
  formdata.append("email", carDetails?.email);
  formdata.append("phone_number", carDetails?.phone);
  formdata.append("airport_id", airport?.id + "");
  formdata.append("package_id", selectedPackage?.id) + "";
  // formdata.append("car_make", carDetails?.carMake);
  // formdata.append("car_model", carDetails?.model);
  // formdata.append("car_color", carDetails?.color);
  formdata.append(
    "check_in",
    moment(checkin, DATE_FORMAT).format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append(
    "check_out",
    moment(checkout, DATE_FORMAT).format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append("sub_total", Number(payment?.subtotal).toFixed(2) + "");
  formdata.append("discount", Number(payment?.discountAmount).toFixed(2) + "");
  formdata.append("total", payment?.total + "");
  formdata.append(
    "due_now",
    (payment?.total - payment?.depositAmount).toFixed(2) + ""
  );
  formdata.append("payment_method_name", "Stripe");
  formdata.append("payment_transaction_id", paymentId);
  formdata.append("payment_card_name", transaction.cardInformation.brand);
  formdata.append("payment_card_number", transaction.cardInformation?.last4);
  formdata.append(
    "payment_card_expiry",
    transaction.cardInformation?.exp_month +
    " " +
    transaction.cardInformation?.exp_year
  );
  formdata.append("payment_card_cvc", "000");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(
    "https://parkndepart.io/backend/public/api/reservation",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => onResponse(true, result))
    .catch((error) => console.error(error));
};
